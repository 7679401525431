<template>
  <section class="section-box section-box--fullblack section-box--first">
    <div class="wrapper">
      <div class="page-not-found">
        <v-icon-404 class="page-not-found__icon"/>
        <p class="page-not-found__title">Sorry. the content you’re looking for doesn’t exist. Either it was removed, or you mistyped the link.</p>
        <router-link to="/" class="action-link action-link--simple">Open Home page</router-link>
      </div>
    </div>
  </section>
</template>

<script>
//Icons
import Icon404 from '@/assets/icons/404.svg'

export default {
  name: "Page-not-found",
  components: {
    'v-icon-404': Icon404
  }
}
</script>

<style lang="scss">
.page-not-found {
  max-width: 800px;
  padding-bottom: 100px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: $viewport-mobile) {
    padding-bottom: 0;
  }
}
.page-not-found__icon {
  width: 369px;
  height: 152px;
  margin-bottom: 65px;
  max-width: 100%;
}
.page-not-found__title {
  margin-bottom: 52px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;
  color: #fff;

  @media (max-width: $viewport-tablet) {
    font-size: 1.75rem;
  }
}

</style>
